import React from 'react';
import { Link } from 'react-router-dom';
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';

interface LinkIconButtonProps extends IconButtonProps {
  to: string;
  replace?: boolean;
  external?: boolean;
}

const ExternalLink: React.FunctionComponent<LinkIconButtonProps> = (props) => {
  const { to, external, ...rest } = props;
  return (
    <a
      href={to}
      target="_blank"
      { ...rest }
    />
  );
};

export default (props: LinkIconButtonProps) => (
  <IconButton
    { ...props }
    component={
      props.external ? ExternalLink as any : Link as any
    }
  />
);
