import React from 'react';
import styled from 'styled-components';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import parse from 'date-fns/parse';
import compareAsc from 'date-fns/compare_asc';

import { Club } from '../../state/club';

interface ClubListItemProps {
  club: Club;
  onClick(): void;
}

const Title = styled.div`
  font-weight: 700;
`;

const getPaymentStatus = (club: Club) => {
  if (club.member_count >= club.maximum_capacity) {
    return '모집 종료';
  }
  if (club.member_count > club.maximum_capacity - 2) {
    return '마감 임박!';
  }
  if (club.member_count >= club.minimum_capacity) {
    return '모임 확정! 추가모집중';
  }
  return '얼리버드 할인중!';
};

const ClubListItem: React.FunctionComponent<ClubListItemProps> = (props) => {

  const saleFinished =  compareAsc(parse(props.club.sale_finish), new Date()) === -1;

  return (
    <Card>
      <CardActionArea onClick={props.onClick}>
        <CardMedia
          style={{
            height: 0,
            paddingTop: '100%',
          }}
          image={props.club.image}
          title={props.club.name}
        />
        <CardContent>
          <Grid container justify="space-between">
            <Typography variant="h5" color="primary">
              <Title>
                {props.club.name}
              </Title>
            </Typography>
            {!saleFinished && <Chip label={getPaymentStatus(props.club)}/>}
          </Grid>
          <Typography variant="subtitle1" color="primary">
            {props.club.subtitle}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default ClubListItem;
