import React from 'react';
import ReactSVG from 'react-svg';

import Fab from '@material-ui/core/Fab';
import kakaotalkSvg from '../../assets/images/social/kakaotalk.svg';

import * as colors from '../../assets/values/colors';
import * as strings from '../../assets/values/strings';

const SvgIcon: React.FunctionComponent<{ src: string }> = (props) => {
  return (
    <ReactSVG
      style={{ fontSize: 0 }}
      svgStyle={{ height: 24, width: 24, fill: colors.textWhite }}
      src={props.src}
      wrapper="span"
    />
  );
};

const BottomFixedChat: React.FunctionComponent<{}> = (props) => {
  return (
    <a
      href={strings.kakaoChatUrl}
      target="_blank"
      style={{
        position: 'fixed',
        bottom: 24,
        right: 24,
        zIndex: 99,
      }}
    >
      <Fab
        color="primary"
        aria-label="Add"
      >
        <SvgIcon src={kakaotalkSvg}/>
      </Fab>
    </a>
  );
};

export default BottomFixedChat;
