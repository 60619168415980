import { Action, AnyAction } from 'redux';
import api, { setAccessToken, ApiPromiseAction, ApiAction } from '../api';
import { fulfilled, pending } from './helper';
import { string } from 'prop-types';

const INITIALIZE_LIST = 'club/initializeList';
const READ_CLUBS = 'club/readClubs';
const READ_CLUB_DETAIL = 'club/readClubDetail';
const SET_CLUB_FILTER = 'club/setClubFilter';

export function initializeList(): Action {
  return {
    type: INITIALIZE_LIST,
  };
}

export function readClubs(filter = {}, cursor?: string): ApiPromiseAction {
  const request = api.get('/clubs/', { ...filter, cursor });
  return {
    type: READ_CLUBS,
    payload: request,
    meta: { cursor },
  };
}

export function readClubDetail(id: number): ApiPromiseAction {
  const request = api.get(`/clubs/${id}/`);
  return {
    type: READ_CLUB_DETAIL,
    payload: request,
  };
}

export function setClubFilter(filter: ClubFilter, tab: number) {
  return {
    type: SET_CLUB_FILTER,
    payload: { filter, tab },
  };
}

export interface ClubFilter {
  [index:string]: string | undefined;
  sale_finish__gt?: string;
  sale_finish__lte?: string;
  meeting_finish__gt?: string;
  meeting_finish__lte?: string;
}

export interface Club {
  id: number;
  name: string;
  image: string;
  subtitle: string;
  short_description: string;
  description: string;
  sale_start: string;
  sale_finish: string;
  maximum_capacity: number;
  minimum_capacity: number;
  total_meetings: number;
  total_month: number;
  member_count: number;
  full_price: number;
  discounted_price: number;
}

export interface ClubState {
  list: Club[];
  filter: ClubFilter;
  filterTab: number;
  next: string | null;
  previous: string | null;
  detail: Club | undefined;
  loading: boolean;
}

const INITIAL_CLUB_STATE: ClubState = {
  list: [],
  filter: {},
  filterTab: 0,
  next: null,
  previous: null,
  detail: undefined,
  loading: true,
};

const getClubList = (state: ClubState, action: AnyAction | ApiAction) => {
  const { results } = action.payload.data;
  if (action.meta.cursor) {
    const list = state.list;
    list.push(...results);
    return list;
  }
  return results;
};

export default function (
  state: ClubState = INITIAL_CLUB_STATE,
  action: AnyAction | ApiAction,
): ClubState {
  switch (action.type) {
    case INITIALIZE_LIST: {
      return INITIAL_CLUB_STATE;
    }
    case pending(READ_CLUBS): {
      return {
        ...state,
        loading: true,
      };
    }
    case fulfilled(READ_CLUBS): {
      const { next, previous } = action.payload.data;
      const list = getClubList(state, action);
      return {
        ...state,
        next,
        previous,
        list,
        loading: false,
      };
    }
    case pending(READ_CLUB_DETAIL): {
      return {
        ...state,
        detail: undefined,
        loading: true,
      };
    }
    case fulfilled(READ_CLUB_DETAIL): {
      return {
        ...state,
        detail: action.payload.data,
        loading: false,
      };
    }
    case SET_CLUB_FILTER: {
      return {
        ...state,
        filter: action.payload.filter,
        filterTab: action.payload.tab,
      };
    }
    default: {
      return state;
    }
  }
}
