import React from 'react';
import styled from 'styled-components';

import * as colors from '../../assets/values/colors';

const Wrapper = styled.div`
  text-align: center;
  padding: 60px 16px;
`;

const Text = styled.div`
  font-size: 32px;
  font-weight: 600;
  color: ${colors.textBlue80};
`;

const ComingSoon: React.FunctionComponent = () => {
  return (
    <Wrapper>
      <Text>
        아직 모임이 없습니다
      </Text>
    </Wrapper>
  );
};

export default ComingSoon;
