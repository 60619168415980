import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import styled  from 'styled-components';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { readFeaturedNotices, NoticeState } from '../../state/notice';
import { AppState } from '../../state/rootReducer';

interface FeaturedNoticeProps {
  readFeaturedNotices: typeof readFeaturedNotices;
}

const Wrapper = styled.div`
  padding: 8px 16px;
`;

const FeaturedNotice: React.FunctionComponent<FeaturedNoticeProps & NoticeState> = (props) => {
  const initializedRef = useRef(false);

  useEffect(() => {
    if (!initializedRef.current) {
      props.readFeaturedNotices();
      initializedRef.current = true;
    }
  });

  if (!props.featuredList || !props.featuredList[0]) {
    return <div/>;
  }

  return (
    <Paper square elevation={1} style={{ marginTop: 1 }}>
      <Wrapper>
        <Link to={`/notices/${props.featuredList[0].id}`}>
          <Typography color="textPrimary" variant="subtitle1" align="center">
            {props.featuredList[0].title}
          </Typography>
        </Link>
      </Wrapper>
    </Paper>
  );
};

const mapStateToProps = (state: AppState) => ({
  featuredList: state.notice.featuredList,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  { readFeaturedNotices },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(FeaturedNotice) as any;
