import React from 'react';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import Header from './components/base/Header';
import Footer from './components/base/Footer';
import FeaturedNotice from './components/notice/FeaturedNotice';
import NoticeList from './components/notice/NoticeList';
import NoticeDetail from './components/notice/NoticeDetail';
import SignUp from './components/auth/SignUp';
import SignIn from './components/auth/SignIn';
import ClubList from './components/club/ClubList';
import ClubDetail from './components/club/ClubDetail';
import Home from './components/landing/Home';
import PaymentDetail from './components/payment/PaymentDetail';
import PaymentComplete from './components/payment/PaymentComplete';
import ReviewList from './components/review/ReviewList';
import UserInfo from './components/user/UserInfo';
import ForgotPassword from './components/user/ForgotPassword';
import ResetPassword from './components/user/ResetPassword';
import FAQ from './components/utility/FAQ';
import Terms from './components/utility/Terms';
import Privacy from './components/utility/Privacy';
import Editor from './components/board/PostEditor';

import ErrorBoundary from './components/utility/ErrorBoundary';
import RouterError from './components/utility/RouterError';
import AppSnackbar from './components/utility/AppSnackbar';
import ScrollToTop from './components/utility/ScrollToTop';
import Tracker from './components/utility/Tracker';
import ConfirmDialog from './components/utility/ConfirmDialog';

import configureStore from './state/configureStore';
import theme from './theme';
import * as strings from './assets/values/strings';
import * as styles from './assets/values/styles';
import './index.css';

export const store = configureStore();
const persistor = persistStore(store);

const Content = styled.div`
  min-height: calc(100vh - ${styles.footerHeight});
`;

const routes = strings.routes;

const App = () => (
  <ErrorBoundary>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <MuiThemeProvider theme={theme}>
          <Router>
            <React.Fragment>
              <Content>
                <Helmet
                  title={strings.appName}
                  meta={[
                    {
                      name: 'description',
                      content: strings.appDescription,
                    },
                  ]}
                />
                <Header/>
                <FeaturedNotice/>
                <ScrollToTop/>
                <Tracker/>
                <Switch>
                  <Route exact path={routes.root} component={Home}/>
                  <Route exact path={routes.clubList} component={ClubList}/>
                  <Route path={routes.clubDetail} component={ClubDetail}/>
                  <Route path={routes.paymentComplete} component={PaymentComplete}/>
                  <Route path={routes.paymentDetail} component={PaymentDetail}/>
                  <Route path={routes.reviewList} component={ReviewList}/>
                  <Route path={routes.user} component={UserInfo}/>
                  <Route path={routes.signUp} component={SignUp}/>
                  <Route path={routes.signIn} component={SignIn}/>
                  <Route path={routes.forgotPassword} component={ForgotPassword}/>
                  <Route path={routes.resetPassword} component={ResetPassword}/>
                  <Route path={routes.noticeList} component={NoticeList}/>
                  <Route path={routes.noticeDetail} component={NoticeDetail}/>
                  <Route path={routes.faq} component={FAQ}/>
                  <Route path={routes.terms} component={Terms}/>
                  <Route path={routes.privacy} component={Privacy}/>
                  <Route path="/editor" component={Editor}/>
                  <Route component={RouterError}/>
                </Switch>
                {/* <PaymentDialog/> */}
                <AppSnackbar/>
                <ConfirmDialog/>
              </Content>
              <Footer/>
            </React.Fragment>
          </Router>
        </MuiThemeProvider>
      </PersistGate>
    </Provider>
  </ErrorBoundary>
);

export default App;
