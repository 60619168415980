import React, { useRef, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Helmet } from 'react-helmet';
import styled  from 'styled-components';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PageNumberPaginator from '../utility/PageNumberPaginator';

import { readNotices, Notice, NoticeState } from '../../state/notice';
import { AppState } from '../../state/rootReducer';

import { formatDateTime } from '../../utils/formatter';

interface NoticeListProps extends NoticeState, RouteComponentProps {
  readNotices: typeof readNotices;
}

const Wrapper = styled.div`
  padding: 16px;
  margin: 60px auto;
  max-width: 700px;
`;

const NoticeList: React.FunctionComponent<NoticeListProps> = (props) => {
  const initializedRef = useRef(false);

  useEffect(() => {
    if (!initializedRef.current) {
      props.readNotices();
      initializedRef.current = true;
    }
  });

  const handleNoticeClick = (notice: Notice) => {
    props.history.push(`/notices/${notice.id}`);
  };

  const handleNoticesRead = (page: number) => {
    props.readNotices(page);
  };

  return (
    <Wrapper>
      <Helmet
        title="공지사항 | 향유고래"
      />
      <Typography variant="h4" gutterBottom>
        공지사항
      </Typography>
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>제목</TableCell>
              <TableCell style={{ width: '6em' }}>날짜</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.list.map(notice => (
              <TableRow
                key={notice.id}
                onClick={() => handleNoticeClick(notice)}
                hover
                style={{ cursor: 'pointer' }}
              >
                <TableCell>{notice.title}</TableCell>
                <TableCell>{formatDateTime(notice.created_at)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <PageNumberPaginator
          currentPage={props.currentPage}
          count={props.count}
          onPageClick={handleNoticesRead}
        />
      </Paper>
    </Wrapper>
  );
};

const mapStateToProps = (state: AppState) => ({
  list: state.notice.list,
  currentPage: state.notice.currentPage,
  count: state.notice.count,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  { readNotices },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(NoticeList);
