import React, { useEffect, useRef } from 'react';
import { match } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Helmet } from 'react-helmet';
import styled  from 'styled-components';
import ReactMarkdown from 'react-markdown';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import LinkButton from '../base/LinkButton';
import Progress from '../base/Progress';

import { readNotice, NoticeState } from '../../state/notice';
import { AppState } from '../../state/rootReducer';

import { formatDateTime } from '../../utils/formatter';

interface FeaturedNoticeProps {
  readNotice: typeof readNotice;
  match: match<{noticeId: string}>;
}

const Wrapper = styled.div`
  padding: 16px;
  margin: 60px auto;
  max-width: 700px;
`;

const Actions = styled.div`
  margin-top: 16px;
  text-align: right;
`;

const NoticeDetail: React.FunctionComponent<FeaturedNoticeProps & NoticeState> = (props) => {
  const initializedRef = useRef(false);

  useEffect(() => {
    if (!initializedRef.current) {
      props.readNotice(parseInt(props.match.params.noticeId, 10));
      initializedRef.current = true;
    }
  });

  if (!props.detail) {
    return <Progress/>;
  }

  return (
    <Wrapper>
      <Helmet
        title="공지사항 | 향유고래"
      />
      <Card>
        <CardHeader
          title={props.detail.title}
          subheader={formatDateTime(props.detail.created_at)}
        />
        <CardContent>
          <ReactMarkdown escapeHtml={false} source={props.detail.body}/>
        </CardContent>
      </Card>
      <Actions>
        <LinkButton
          to="/notices"
          variant="outlined"
        >
          목록으로
        </LinkButton>
      </Actions>
    </Wrapper>
  );
};

const mapStateToProps = (state: AppState) => ({
  detail: state.notice.detail,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  { readNotice },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(NoticeDetail) as any;
