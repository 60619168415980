import { applyMiddleware, createStore } from 'redux';
import promiseMiddleware from 'redux-promise-middleware';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/es/storage';

import rootReducer from './rootReducer';

const persistConfig = {
  storage,
  key: 'hyangugorae-v0.1.0',
  whitelist: ['auth'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const configureStore = () => createStore(
  persistedReducer,
  applyMiddleware(
    promiseMiddleware,
  ),
);

export default () => {
  const store = configureStore();
  return store;
};
