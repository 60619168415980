import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import ResetPasswordForm, { ResetPasswordFormData } from './ResetPasswordForm';
import snackbar from '../../utils/snackbar';

import { AuthState } from '../../state/auth';
import { resetPassword, UserState } from '../../state/user';
import { AppState } from '../../state/rootReducer';

const Wrapper = styled.div`
  margin: 60px auto;
  max-width: 400px;
  padding: 0 16px;
`;

interface ForgotPasswordProps {
  resetPassword: typeof resetPassword;
}

class ForgotPassword extends React.Component<ForgotPasswordProps & UserState & AuthState
& RouteComponentProps> {

  getToken = () => {
    const name = 'token';
    const parsed = queryString.parse(location.search);
    return parsed[name] || '';
  }

  handleSubmit = (data: object) => {
    const token = this.getToken();
    return this.props.resetPassword({ ...data, token });
  }

  componentWillMount() {
    if (this.props.signedIn) {
      this.props.history.goBack();
    }
  }

  componentDidUpdate() {
    if (this.props.resetPasswordSuccess) {
      snackbar.success('비밀번호를 성공적으로 변경했습니다');
      this.props.history.push('/sign_in');
    }
  }

  render() {
    return (
      <Wrapper>
        <Helmet
          title="비밀번호 변경 | 향유고래"
        />
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h5">
              비밀번호 변경
            </Typography>
            <Typography gutterBottom variant="body1">
              새롭게 사용하실 비밀번호를 적어주세요.
            </Typography>
            <ResetPasswordForm
              onSubmit={this.handleSubmit}
            />
          </CardContent>
        </Card>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  signedIn: state.auth.signedIn,
  resetPasswordSuccess: state.user.resetPasswordSuccess,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  { resetPassword },
  dispatch,
);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ForgotPassword));
