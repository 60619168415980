import numeral from 'numeral';
import format from 'date-fns/format';

export const formatKRW = (number: number) => {
  return `${numeral(number).format('0,0')}원`;
};

export const formatDateTime = (dateTime: string) => {
  return format(dateTime, 'YYYY-MM-DD HH:mm');
};
