import { AnyAction } from 'redux';

const SET_PAYMENT_DATA = 'payment/setPaymentData';
const RESET_PAYMENT_DATA = 'payment/resetPaymentData';
const START_CHECKOUT = 'payment/startCheckout';
const FINISH_CHECKOUT = 'payment/finishCheckout';

export interface PaymentCustomData {
  club_id: number;
  user_id: number | undefined;
}

export interface PaymentData {
  merchant_uid: string; // (필수) `merchant_${md5((new Date()).toString()).slice(-11)}`
  amount: number;  // (필수)
  name: string; // (필수)
  buyer_name: string;
  buyer_email?: string;
  buyer_tel?: string;
  custom_data: PaymentCustomData;
  m_redirect_url: string; // 결제 완료 혹은 실패 후 리다이렉트할 URL (데스크톱은 내부 callback / 모바일은 PG 모듈이 처리)
}

export interface Transaction {
  id: number;
  amount: number;
  receipt_url: string;
  is_cancelled: boolean;
  created_at: string;
}

export function setPaymentData(data: PaymentData): AnyAction {
  return {
    type: SET_PAYMENT_DATA,
    payload: data,
  };
}

export function resetPaymentData(): AnyAction {
  return {
    type: RESET_PAYMENT_DATA,
  };
}

export function startCheckout(): AnyAction {
  return {
    type: START_CHECKOUT,
  };
}

export function finishCheckout(): AnyAction {
  return {
    type: FINISH_CHECKOUT,
  };
}

export interface PaymentState {
  data: PaymentData | undefined;
  dialogOpen: boolean;
  checkingOut: boolean;
}

const INITIAL_PAYMENT_STATE: PaymentState = {
  data: undefined,
  dialogOpen: false,
  checkingOut: false,
};

export default function (
  state: PaymentState = INITIAL_PAYMENT_STATE,
  action: AnyAction,
): PaymentState {
  switch (action.type) {
    case RESET_PAYMENT_DATA: {
      return {
        ...state,
        data: undefined,
        dialogOpen: false,
      };
    }
    case SET_PAYMENT_DATA: {
      return {
        ...state,
        data: action.payload,
        dialogOpen: true,
      };
    }
    case START_CHECKOUT: {
      return {
        ...state,
        checkingOut: true,
      };
    }
    case FINISH_CHECKOUT: {
      return {
        ...state,
        checkingOut: false,
      };
    }
    default: {
      return state;
    }
  }
}
