import React, { useEffect, ChangeEvent } from 'react';
import { match, RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import styled from 'styled-components';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import Progress from '../base/Progress';
import SignMixin from '../auth/SignMixin';
import ClubInfo from '../club/ClubInfo';

import { JwtPayload } from '../../state/auth';
import { resetPaymentData, setPaymentData, startCheckout,
  PaymentState, PaymentData } from '../../state/payment';
import { readClubDetail, ClubState } from '../../state/club';
import { AppState } from '../../state/rootReducer';

import { requestPay, generateInitialPaymentData } from '../../utils/billing';
import LinkButton from '../base/LinkButton';

import * as strings from '../../assets/values/strings';

const Wrapper = styled.div`
  margin: 60px 16px;
`;

const Spacer = styled.div`
  height: 16px;
`;

interface PaymentDialogProps {
  jwtPayload: JwtPayload | undefined;
  signedIn: boolean;
  readClubDetail: typeof readClubDetail;
  setPaymentData: typeof setPaymentData;
  resetPaymentData: typeof resetPaymentData;
  match: match<{clubId: string }>;
}

const PaymentDialog: React.FunctionComponent<PaymentDialogProps & PaymentState
& ClubState & RouteComponentProps> = (props) => {

  useEffect(() => {
    const clubId = parseInt(props.match.params.clubId, 10);
    if (!props.detail || props.detail.id !== clubId) {
      props.readClubDetail(clubId);
      return;
    }

    if (!props.data || (props.data && props.data.custom_data.club_id !== props.detail.id)) {
      props.setPaymentData(generateInitialPaymentData(props.detail));
      return;
    }

    if (!props.data.custom_data.user_id && props.jwtPayload) {
      const newData: PaymentData = {
        ...props.data,
        custom_data: {
          ...props.data.custom_data,
          user_id: props.jwtPayload.user_id,
        },
      };
      props.setPaymentData(newData);
    }
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!props.data) return;

    const newData: PaymentData = {
      ...props.data,
      [event.currentTarget.name]: event.currentTarget.value,
    };
    props.setPaymentData(newData);
  };

  const handlePurchase = () => {
    if (!props.data) return;

    const data = props.data;
    if (!data.buyer_name && data.buyer_email) {
      data.buyer_name = data.buyer_email;
    }
    requestPay(props.data);
  };

  if (!props.detail || !props.data) {
    return <Progress/>;
  }

  return (
    <Wrapper>
      <Grid container justify="center">
        <Grid item xs={12} lg={4}>
          <Typography gutterBottom variant="h4" color="textPrimary" style={{ fontWeight: 700 }}>
            모임 신청하기
          </Typography>
          <Spacer/>
          {props.data && props.detail && (
            <Card>
              <CardMedia
                style={{
                  height: 0,
                  paddingTop: '100%',
                }}
                image={props.detail.image}
                title={props.detail.name}
              />
              <CardContent>
                <ClubInfo
                  detail={props.detail}
                  showPrice
                />
              </CardContent>
            </Card>
          )}
          <Spacer/>
          {!props.signedIn &&
          <Card>
            <CardContent>
              <Typography gutterBottom variant="subtitle1">
                모임 신청을 위해 로그인이 필요합니다
              </Typography>
              <SignMixin/>
            </CardContent>
          </Card>
          }
          {props.signedIn &&
          <div>
            <Card>
              <CardContent>
                <Typography variant="h6" color="primary">
                  결제 정보
                </Typography>
                <div>
                  <TextField
                    name="buyer_tel"
                    label="휴대전화 번호"
                    helperText="모임 진행을 위해 연락받을 수 있는 휴대전화 번호를 적어주세요"
                    variant="outlined"
                    margin="dense"
                    onChange={handleChange}
                    fullWidth
                  />
                </div>
                <div>
                  <TextField
                    name="buyer_email"
                    label="이메일"
                    helperText="결제결과를 전달받을 이메일 주소를 적어주세요"
                    variant="outlined"
                    margin="dense"
                    onChange={handleChange}
                    fullWidth
                  />
                </div>
              </CardContent>
            </Card>
            <Spacer/>
            <div>
              {/* <Typography variant="subtitle2" color="primary">
                현재 국민카드와 외환카드의 사용이 잠시 불가능합니다.<br/>
                무통장입금을 통한 결제를 원하신다면 카카오톡 플러스친구로 문의해주세요 :)
              </Typography>
              <Spacer/> */}
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                onClick={handlePurchase}
                disabled={props.checkingOut}
              >
                결제하기
              </Button>
              <Spacer/>
              <LinkButton
                to={strings.kakaoChatUrl}
                variant="outlined"
                external
                fullWidth
                size="small"
              >
                {strings.contactWithKakaoTalk}
              </LinkButton>
            </div>
          </div>
          }
          <Spacer/>
          <Divider/>
          <Spacer/>
          <Typography variant="subtitle2">
            환불규정
          </Typography>
          <Typography variant="caption">
            - 결제이후 ~ 1회차 모임의 1일전: 결제금액 전액환불<br/>
            - 1회차 모임 당일 ~ 2회차 모임의 1일전: 결제금액의 80% 환불<br/>
            - 2회차 모임 당일 ~ 3회차 모임의 1일전: 결제금액의 60% 환불<br/>
            - 3회차 모임 당일 ~ 4회차 모임의 1일전: 결제금액의 40% 환불<br/>
            - 4회차 모임 당일 이후: 환불불가
          </Typography>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

const mapStateToProps = (state: AppState) => ({
  data: state.payment.data,
  checkingOut: state.payment.checkingOut,
  detail: state.club.detail,
  dialogOpen: state.payment.dialogOpen,
  signedIn: state.auth.signedIn,
  jwtPayload: state.auth.jwtPayload,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  { readClubDetail, setPaymentData, startCheckout },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDialog) as any;
