import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import ClipboardCopy from 'clipboard-copy';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import LinkIcon from '@material-ui/icons/Link';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PostEditor from '../board/PostEditor';
import Comment from '../board/Comment';
import CommentEditor from '../board/CommentEditor';
import PostBody from '../board/PostBody';

import { AuthState } from '../../state/auth';
import { Membership } from '../../state/membership';
import { updatePost, deletePost, readComments, createComment,
  BoardState, Post } from '../../state/board';
import { AppState } from '../../state/rootReducer';

import { formatDateTime } from '../../utils/formatter';
import { askConfirmDelete } from '../../utils/confirm';

import 'react-quill/dist/quill.snow.css';

interface ClubPostCardProps extends BoardState, AuthState {
  post: Post;
  memberships: Membership[];
  raised?: boolean;
  updatePost: typeof updatePost;
  deletePost: typeof deletePost;
  readComments: typeof readComments;
  createComment: typeof createComment;
}

const ClubPostCard: React.FunctionComponent<ClubPostCardProps> = (props) => {

  const [commentExpanded, setCommentExpanded] = useState(false);
  const [edit, setEdit] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const handleCommentExpanded = () => {
    setCommentExpanded(!commentExpanded);
    if (!commentExpanded) {
      props.readComments(props.post.id);
    }
  };

  const handleCommentSubmit = (body: string) => {
    const user = props.jwtPayload && props.jwtPayload.user_id;
    if (!user) return;

    const data = {
      body,
      user,
      post: props.post.id,
    };
    props.createComment(data);
  };

  const handlePostSubmit = (body: string) => {
    const user = props.jwtPayload && props.jwtPayload.user_id;
    if (!user) return;
    const data = {
      body,
    };
    props.updatePost(props.post.id, data);
    handleEditClose();
  };

  const handlePostDelete = () => {
    props.deletePost(props.post.id);
    handleActionClose();
  };

  const handleActionOpen = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleActionClose = () => {
    setAnchorEl(null);
  };

  const handleEditOpen = () => {
    setEdit(true);
    handleActionClose();
  };

  const handleEditClose = () => {
    setEdit(false);
  };

  const handleCopyURL = () => {
    ClipboardCopy(`https://hyangyugorae.com/clubs/${props.post.club}/posts/${props.post.id}`)
      .then(() => handleActionClose());
  };

  const authorizeEditor = () => {
    if (!props.memberships) return false;
    const membershipClubs = props.memberships.map(m => m.club);
    const club = props.post.club;
    return membershipClubs.includes(club);
  };

  const authorizeAuthor = () => {
    return props.post.user === (props.jwtPayload && props.jwtPayload.user_id);
  };

  return (
    <Card elevation={props.raised ? 4 : undefined}>
      <CardHeader
        action={
          <IconButton onClick={handleActionOpen}>
            <MoreVertIcon />
          </IconButton>
        }
        title={<Typography variant="subtitle1">{props.post.user_nickname}</Typography>}
        subheader={formatDateTime(props.post.created_at)}
      />
      <CardContent>
        {edit ?
          <PostEditor
            initialValue={props.post.body}
            onSubmit={handlePostSubmit}
            onCancel={handleEditClose}
            style={{ margin: -16, boxShadow: 'none' }}
          /> :
          <PostBody content={props.post.body}/>
        }
      </CardContent>
      <CardActions disableActionSpacing>
        <Button
          onClick={handleCommentExpanded}
          size="small"
        >
          {props.post.comment_count}개의 댓글
          <ExpandMoreIcon />
        </Button>
      </CardActions>
      <Collapse in={commentExpanded} timeout="auto" unmountOnExit>
        <CardContent>
          {props.post && props.comments[props.post.id] &&
          props.comments[props.post.id].map(comment =>
            <Comment key={comment.id} comment={comment}/>)}
          {authorizeEditor() ?
            <CommentEditor
              onSubmit={handleCommentSubmit}
            /> :
            <Typography variant="caption" color="primary" gutterBottom>
              로그인을 한 모임 참가자만 댓글을 쓸 수 있습니다.
            </Typography>
          }
        </CardContent>
      </Collapse>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleActionClose}
      >
        <MenuItem onClick={handleCopyURL} dense>
          <Typography variant="body2"><LinkIcon fontSize="inherit"/> URL 복사하기</Typography>
        </MenuItem>
        {authorizeAuthor() && [
          <MenuItem key="수정하기" onClick={handleEditOpen} dense>
            <Typography variant="body2"><EditIcon fontSize="inherit"/> 수정하기</Typography>
          </MenuItem>,
          <MenuItem key="삭제하기" onClick={askConfirmDelete(handlePostDelete)} dense>
            <Typography variant="body2"><DeleteIcon fontSize="inherit"/> 삭제하기</Typography>
          </MenuItem>,
        ]}
      </Menu>
    </Card>
  );
};

const mapStateToProps = (state: AppState) => ({
  comments: state.board.comments,
  jwtPayload: state.auth.jwtPayload,
  memberships: state.membership.list,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  { updatePost, deletePost, readComments, createComment },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(ClubPostCard) as any;
