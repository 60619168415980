import React, { ChangeEvent } from 'react';
import { match, RouteComponentProps } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import parse from 'date-fns/parse';
import compareAsc from 'date-fns/compare_asc';

import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';
import Divider from '@material-ui/core/Divider';

import { getClubFilter } from './ClubList';
import ClubInfo from './ClubInfo';
import ClubListItem from './ClubListItem';
import StickyTabs from './StickyTabs';
import ClubDescription from './ClubDescription';
import ClubPostList from './ClubPostList';
import ClubPostDetail from './ClubPostDetail';
import Progress from '../base/Progress';
import BottomFixedChat from '../utility/BottomFixedChat';

import { AuthState } from '../../state/auth';
import { readClubs, readClubDetail, Club } from '../../state/club';
import { readMemberships, Membership } from '../../state/membership';
import { resetPaymentData } from '../../state/payment';
import { AppState } from '../../state/rootReducer';

import * as colors from '../../assets/values/colors';
import * as strings from '../../assets/values/strings';
import { Typography } from '@material-ui/core';

const Wrapper = styled.div`
  margin: 60px auto;
  padding: 0px 16px;
  max-width: 900px;
`;

const Section = styled.div`
  margin: 24px 0px;
`;

const Spacer = styled.div`
  height: 16px;
`;

interface ClubDetailProps {
  list: Club[];
  detail: Club | undefined;
  loading: boolean;
  memberships: Membership[];
  readClubs: typeof readClubs;
  readClubDetail: typeof readClubDetail;
  resetPaymentData: typeof resetPaymentData;
  readMemberships: typeof readMemberships;
  match: match<{clubId: string }>;
}

const clubDetailTabs = [
  {
    value: 0,
    label: '소개',
    path: '',
  },
  {
    value: 1,
    label: '커뮤니티',
    path: 'posts',
  },
];

class ClubDetail extends React.Component<
  ClubDetailProps & AuthState & RouteComponentProps
> {

  isSoldOut = () => {
    if (!this.props.detail) return false;
    return this.props.detail.member_count === this.props.detail.maximum_capacity;
  }

  isPurchased = () => {
    if (!this.props.memberships) return false;
    const membershipClubs = this.props.memberships.map(membership => membership.club);
    const clubId = parseInt(this.props.match.params.clubId, 10);
    return membershipClubs.includes(clubId);
  }

  isSaleFinished = () => {
    if (!this.props.detail) return true;
    return compareAsc(parse(this.props.detail.sale_finish), new Date()) === -1;
  }

  handlePurchaseClick = () => {
    const clubId = parseInt(this.props.match.params.clubId, 10);
    this.props.resetPaymentData();
    this.props.history.push(`/payment/${clubId}`);
  }

  handleTabChange = (event: ChangeEvent<{}>, value: number) => {
    const newPath = `/clubs/${this.props.match.params.clubId}/${clubDetailTabs[value].path}`;
    this.props.history.push(newPath);
  }

  getActiveTabValue = (url: string) => {
    for (let index = 1; index < clubDetailTabs.length; index += 1) {
      const element = clubDetailTabs[index];
      if (url.includes(element.path)) {
        return element.value;
      }
    }
    return 0;
  }

  componentDidMount() {
    const clubId = parseInt(this.props.match.params.clubId, 10);
    this.props.readClubDetail(clubId);
    this.props.readClubs(getClubFilter(0));
    if (this.props.jwtPayload) {
      this.props.readMemberships();
    }
  }

  componentDidUpdate() {
    const clubId = parseInt(this.props.match.params.clubId, 10);
    if (this.props.detail && this.props.detail.id !== clubId) {
      this.props.readClubDetail(clubId);
    }
  }

  render() {
    if (!this.props.detail || this.props.loading) {
      return <Progress/>;
    }

    return (
      <Wrapper>
        <Helmet
          title={`${this.props.detail.name} | ${strings.appName}`}
        />
        <Section>
          <Grid container spacing={32} justify="center">
            <Grid item xs={12} lg={7}>
              <CardMedia
                style={{
                  height: 0,
                  paddingTop: '100%',
                }}
                image={this.props.detail.image}
              />
            </Grid>
            <Grid item xs={12} lg={5}>
              <ClubInfo
                showPayment={!this.isSaleFinished()}
                showPrice={!this.isSaleFinished()}
                detail={this.props.detail}
                onButtonClick={this.handlePurchaseClick}
                buttonDisabled={this.isSoldOut() || this.isPurchased()}
                buttonLabel={
                  this.isPurchased() ? strings.membershipPurchased : strings.purchaseMembership
                }
              />
            </Grid>
          </Grid>
        </Section>
        <Divider/>
        <StickyTabs
          color="primary"
          variant="contained"
          size="large"
          fullWidth
          onClick={this.handlePurchaseClick}
          disabled={this.isSoldOut() || this.isPurchased() || this.isSaleFinished()}
          label={this.isPurchased() ? strings.membershipPurchased : strings.purchaseMembership}
          title={this.props.detail.name}
          onTabChange={this.handleTabChange}
          tabs={clubDetailTabs}
          tabValue={this.getActiveTabValue(location.href)}
        />
        <Switch>
          <Route
            path={strings.routes.clubDetailPostDetail}
            component={ClubPostDetail}
          />
          <Route
            path={strings.routes.clubDetailPostList}
            component={ClubPostList}
          />
          <Route
            path={strings.routes.clubDetail}
            render={() =>
              <ClubDescription
                name={this.props.detail && this.props.detail.name}
                subtitle={this.props.detail && this.props.detail.name}
                description={this.props.detail && this.props.detail.description}
              />
            }
          />
        </Switch>
        <Divider/>
        <Section>
          <Typography color="primary" variant="h5" style={{ fontWeight: 700 }}>
            {strings.lookAroundOtherClubs}
          </Typography>
          <Spacer/>
          <Grid container spacing={24} justify="center">
          {this.props.list.length > 0 && this.props.list.map((club) => {
            if (this.props.detail && club.id !== this.props.detail.id) {
              return (
                <Grid item xs={12} md={4} key={club.id}>
                  <ClubListItem
                    onClick={() => this.props.history.push(`/clubs/${club.id}`)}
                    club={club}
                  />
                </Grid>
              );
            }
          })}
          </Grid>
        </Section>
        <BottomFixedChat/>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  jwtPayload: state.auth.jwtPayload,
  list: state.club.list,
  detail: state.club.detail,
  loading: state.club.loading,
  memberships: state.membership.list,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  { readClubs, readClubDetail, resetPaymentData, readMemberships },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(ClubDetail);
