import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';

import Progress from '../base/Progress';
import ReviewForm from './ReviewForm';
import ReviewCard from './ReviewCard';

import { JwtPayload } from '../../state/auth';
import { readMemberships, Membership } from '../../state/membership';
import { readReviews, createReview, ReviewState } from '../../state/review';
import { AppState } from '../../state/rootReducer';

interface ReviewListProps extends ReviewState {
  jwtPayload: JwtPayload | undefined;
  memberships: Membership[];
  readReviews: typeof readReviews;
  createReview: typeof createReview;
  readMemberships: typeof readMemberships;
}

const Wrapper = styled.div`
  padding: 16px;
  margin: 60px auto;
  max-width: 700px;
`;

const SectionTitle = styled.div`
  font-size: 24px;
  font-weight: 900;
  margin-bottom: 16px;
`;

const CardWrapper = styled.div`
  margin-top: 12px;
`;

const ReviewList: React.FunctionComponent<ReviewListProps> = (props) => {
  const initialized = useRef(false);

  if (!initialized.current) {
    props.readReviews();
    if (props.jwtPayload) {
      props.readMemberships();
    }
    initialized.current = true;
  }

  if (!props.reviews || props.loadingReviews) {
    return <Progress/>;
  }

  return (
    <Wrapper>
      <Helmet
        title="모임 후기 | 향유고래"
      />
      <SectionTitle>
        모임 후기
      </SectionTitle>
      {props.memberships && props.memberships.length > 0 &&
        <ExpansionPanel style={{ marginBottom: 18 }}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1">후기 쓰기</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <ReviewForm
              style={{ width: '100%' }}
              onSubmit={props.createReview}
              memberships={props.memberships}
              initialValues={{
                user: props.jwtPayload && props.jwtPayload.user_id,
              }}
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      }
      {props.reviews.map(review => (
        <CardWrapper key={review.id}>
          <ReviewCard review={review}/>
        </CardWrapper>
      ))}
    </Wrapper>
  );
};

const mapStateToProps = (state: AppState) => ({
  jwtPayload: state.auth.jwtPayload,
  reviews: state.review.reviews,
  next: state.review.next,
  loadingReviews: state.review.loadingReviews,
  memberships: state.membership.list,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  { readReviews, createReview, readMemberships },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(ReviewList);
