import React, { useState, ChangeEvent } from 'react';
import styled from 'styled-components';
import Sticky, { Status } from 'react-stickynode';

import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Button, { ButtonProps } from '@material-ui/core/Button';

import * as colors from '../../assets/values/colors';

interface StickyTabsProps extends Partial<ButtonProps> {
  label: string;
  title: string;
  tabValue: number;
  onTabChange(event: ChangeEvent<{}>, value: number): void;
  tabs: any[];
}

const Wrapper = styled.div`
  background-color: ${colors.appBackground};
  margin: 0 -20px;
`;

const Container = styled.div`
  padding: 1px 20px 0 20px;
`;

const Title = styled.div`
  color: ${colors.textBlue};
  font-size: 28px;
  font-weight: 900;
`;

const StickyTabs: React.FunctionComponent<StickyTabsProps> = (props) => {
  const [stickyState, setStickyState] = useState(0);

  const handleStateChange = (status: Status) => {
    setStickyState(status.status);
  };

  return (
    <Sticky onStateChange={handleStateChange} innerZ={9}>
      <Wrapper>
        <Container>
          <Grid container justify="center" alignItems="center">
            <Grid item xs={7}>
              {/* <Title>{props.title}</Title> */}
              <Tabs
                value={props.tabValue}
                indicatorColor="primary"
                textColor="primary"
                onChange={props.onTabChange}
              >
                {props.tabs.map(tab =>
                  <Tab key={tab.value} label={tab.label} style={{ padding: '2px 0' }}/>,
                )}
              </Tabs>
            </Grid>
            <Grid item xs={5}>
            {!props.disabled &&
              <Collapse in={stickyState === 2}>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  onClick={props.onClick}
                  disabled={props.disabled}
                  style={{ margin: '6px 0' }}
                >
                  {props.label}
                </Button>
              </Collapse>
            }
            </Grid>
          </Grid>
        </Container>
        {stickyState === 2 && <Divider/>}
      </Wrapper>
    </Sticky>
  );
};

export default StickyTabs;
