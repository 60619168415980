import React from 'react';
import * as Sentry from '@sentry/browser';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LogoSrc from '../../assets/images/brand/logo.png';

import * as strings from '../../assets/values/strings';

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<any, ErrorBoundaryState> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: any, info: any) {
    this.setState({ hasError: true });
    if (process.env.NODE_ENV === 'production') {
      Sentry.withScope((scope) => {
        Object.keys(info).forEach((key) => {
          scope.setExtra(key, info[key]);
        });
        Sentry.captureException(error);
      });
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ textAlign: 'center', paddingTop: 30 }}>
          <img height="40px" src={LogoSrc}/>
          <div style={{ fontSize: 120 }}>X_X</div>
          <div style={{ fontSize: 18 }}>뭔가 잘못됐습니다</div>
          <div style={{ marginTop: 30 }}>
            <Button
              onClick={() => location.reload()}
              variant="outlined"
            >
              새로고침 해보기
            </Button>
          </div>
          <div style={{ marginTop: 30 }}>
            <a href={strings.kakaoChatUrl} target="blank">
              <Button size="small">카카오톡으로 문의하기</Button>
            </a>
            <a href={strings.instagramUrl} target="blank">
              <Button size="small">인스타그램으로 가기</Button>
            </a>
            <a href={strings.facebookUrl} target="blank">
              <Button size="small">페이스북으로 가기</Button>
            </a>
            <br/>
            <Typography>
              {strings.officialEmail}
            </Typography>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
