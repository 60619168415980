import React from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import LinkButton from '../base/LinkButton';

import SignInForm from './SignInForm';

import { ApiAction } from '../../api';
import { AuthState, signIn } from '../../state/auth';
import { AppState } from '../../state/rootReducer';
import snackbar from '../../utils/snackbar';

const Wrapper = styled.div`
  margin: 60px auto;
  max-width: 400px;
  padding: 0 16px;
`;

const SignUpHelper = styled.div`
  margin: 16px 0px;
`;

const Forgot = styled.div`
  margin-top: 16px;
`;

interface SignInProps {
  signIn: typeof signIn;
}

class SignIn extends React.Component<SignInProps & AuthState & RouteComponentProps> {
  handleSubmit = (data: object) => {
    return this.props.signIn(data);
  }

  handleSubmitSuccess = (result: { action: ApiAction }) => {
    if (result.action.payload.ok) {
      snackbar.success('로그인을 성공했습니다');
      this.props.history.goBack();
    } else {
      snackbar.error('로그인을 실패했습니다');
    }
  }

  componentWillMount() {
    if (this.props.signedIn) {
      this.props.history.goBack();
    }
  }

  render() {
    return (
      <Wrapper>
        <Helmet
          title="로그인 | 향유고래"
        />
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              로그인
            </Typography>
            <SignInForm
              onSubmit={this.handleSubmit}
              onSubmitSuccess={this.handleSubmitSuccess}
            />
            <SignUpHelper>
              <Typography variant="body2" align="center">
                아직 회원이 아니신가요?
              </Typography>
            </SignUpHelper>
            <LinkButton
              variant="outlined"
              fullWidth
              to="/sign_up"
            >
              가입하기
            </LinkButton>
            <Forgot>
              <LinkButton
                size="small"
                to="/forgot_password"
              >
                비밀번호를 잊어버렸어요
              </LinkButton>
            </Forgot>
          </CardContent>
        </Card>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  signedIn: state.auth.signedIn,
  signInError: state.auth.signInError,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  { signIn },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
