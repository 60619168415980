import React from 'react';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import ReactSVG from 'react-svg';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LinkButton from './LinkButton';
import LinkIconButton from './LinkIconButton';

import * as colors from '../../assets/values/colors';
import * as styles from '../../assets/values/styles';
import LogoInvertSrc from '../../assets/images/brand/logo-invert.png';
import wavesSrc from '../../assets/images/common/waves.png';
import instagramSvg from '../../assets/images/social/instagram.svg';
import facebookSvg from '../../assets/images/social/facebook.svg';
import kakaotalkSvg from '../../assets/images/social/kakaotalk.svg';
import brunchPng from '../../assets/images/social/brunch.png';
import * as strings from '../../assets/values/strings';

const Waves = styled.div`
  background-image: url(${wavesSrc});
  background-size: cover;
  background-position: top;
  height: 100px;
`;

const Wrapper = styled.div`
  background-color: ${colors.secondaryBlue};
  padding: 16px;
  color: ${colors.textWhite80};
  min-height: calc(${styles.footerHeight} - 100px);
`;

const CompanyInfo = styled.div`
  margin: 8px 8px;
`;

const Copyrights = styled.div`
  padding: 8px 8px;
`;

const Logo = styled.div`
  width: 200px;
`;

const Line = styled.span`
  display: inline-block;
  margin-right: 0.25em;
`;

const SvgIcon: React.FunctionComponent<{ src: string }> = (props) => {
  return (
    <ReactSVG
      style={{ fontSize: 0 }}
      svgStyle={{ height: 24, width: 24, fill: colors.textWhite80 }}
      src={props.src}
      wrapper="span"
    />
  );
};

const Footer: React.FunctionComponent = () => {
  return (
    <div>
      <Waves/>
      <Wrapper>
        <Grid container spacing={16} justify="center">
          <Grid item xs={12} lg={8}>
            <Logo>
              <a href="/">
                <img width="100%" src={LogoInvertSrc}/>
              </a>
            </Logo>
          </Grid>
          <Grid item xs={12} lg={8}>
            <LinkIconButton external to={strings.kakaoFriendUrl} style={{ marginRight: 16 }}>
              <SvgIcon src={kakaotalkSvg}/>
            </LinkIconButton>
            <LinkIconButton external to={strings.brunchUrl} style={{ marginRight: 16 }}>
              <img height={24} width={24} src={brunchPng}/>
            </LinkIconButton>
            <LinkIconButton external to={strings.instagramUrl} style={{ marginRight: 16 }}>
              <SvgIcon src={instagramSvg}/>
            </LinkIconButton>
            <LinkIconButton external to={strings.facebookUrl}>
              <SvgIcon src={facebookSvg}/>
            </LinkIconButton>
          </Grid>
          <Grid item xs={12} lg={8}>
            <LinkButton to="/notices" size="small" color="inherit">
              <Typography
                variant="button"
                color="inherit"
              >
                공지사항
              </Typography>
            </LinkButton>
            <LinkButton to="/terms" size="small" color="inherit">
              <Typography
                variant="button"
                color="inherit"
              >
                이용약관
              </Typography>
            </LinkButton>
            <LinkButton to="/privacy" size="small" color="inherit">
              <Typography
                variant="button"
                color="inherit"
                style={{ fontWeight: 700 }}
              >
                개인정보취급방침
              </Typography>
            </LinkButton>
          </Grid>
          <Grid item xs={12} lg={8}>
            <CompanyInfo>
              <Typography variant="caption" color="inherit" gutterBottom>
                <Line>주식회사 노페땅 |</Line><Line> 대표 김민식 |</Line><Line> 서울시 성동구 왕십리로2길 20, 1층 |</Line>
                <Line>사업자등록 674-87-00398 |</Line><Line>통신판매업 2016-서울성동-00443 |</Line>
                <Line>이메일 hello@hyangyugorae.com</Line>
              </Typography>
            </CompanyInfo>
            <Copyrights>
              <Typography variant="caption" color="inherit">
                © nofetan Inc.
              </Typography>
            </Copyrights>
          </Grid>
        </Grid>
      </Wrapper>
    </div>
  );
};

export default withRouter(Footer);
