import React from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';

import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

import TextField from '../form/TextField';
import Checkbox from '../form/Checkbox';

import * as validator from '../../utils/formValidator';
import * as styles from '../../assets/values/styles';
import * as colors from '../../assets/values/colors';

export interface UserInfoFormData {
  email: string;
  nickname: string;
  phone_number: string;
}

const InfoForm: React.FunctionComponent<InjectedFormProps<UserInfoFormData>> = (props) => {
  return (
    <form onSubmit={props.handleSubmit}>
      <CardContent>
        <div>
          <Field
            name="email"
            component={TextField}
            label="이메일"
            margin="dense"
            validate={[validator.email]}
            disabled
            fullWidth
          />
        </div>
        <div>
          <Field
            name="nickname"
            component={TextField}
            label="이름"
            margin="dense"
            validate={[validator.nickname]}
            fullWidth
          />
        </div>
        <div>
          <Field
            name="phone_number"
            component={TextField}
            label="휴대전화 번호"
            margin="dense"
            validate={[validator.phoneNumber]}
            fullWidth
          />
        </div>
      </CardContent>
      <CardActions>
        <Button
          type="submit"
          color="primary"
          disabled={props.pristine || props.submitting}
        >
          저장하기
        </Button>
      </CardActions>
    </form>
  );
};

export default reduxForm<UserInfoFormData>({
  form: 'infoForm',
})(InfoForm);
