import React from 'react';
import styled from 'styled-components';

import CardMedia from '@material-ui/core/CardMedia';

import * as colors from '../../assets/values/colors';

const Wrapper = styled.div`
  margin: 60px 0px;
`;

const MediaWrapper = styled.div`
  padding: 0px 32px;
`;

const TextWrapper = styled.div<{ color: string, align: string }>`
  margin-top: 46px;
  line-height: 1.5;
  color: ${props => props.color};
  text-align: ${props => props.align};
  padding: 0px 16px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
`;

const Description = styled.div`
  font-size: 16px;
  font-weight: 400;
`;

const Phrase = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-top: 46px;
`;

interface IntroductionItemProps {
  image: string;
  title: string;
  description: string;
  phrase?: string;
  color: string;
  align: string;
}

const IntroductionItem: React.FunctionComponent<IntroductionItemProps> = (props) => {
  return (
    <Wrapper>
      <MediaWrapper>
        <CardMedia
          style={{ height: 0, paddingTop: '100%' }}
          image={props.image}
        />
      </MediaWrapper>
      <TextWrapper
        color={props.color}
        align={props.align}
      >
        <Title>{props.title}</Title>
        <Description>{props.description}</Description>
        {props.phrase && <Phrase>{props.phrase}</Phrase>}
      </TextWrapper>
    </Wrapper>
  );
};

export default IntroductionItem;
