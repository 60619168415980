import React from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinkButton from '../base/LinkButton';
import TextField from '../form/TextField';
import Checkbox from '../form/Checkbox';

import * as validator from '../../utils/formValidator';
import * as styles from '../../assets/values/styles';
import * as colors from '../../assets/values/colors';

const validate = (values: ResetPasswordFormData) => {
  const errors: any = {};
  errors.password_check = validator.passwordCheck(values.password_check, values.password);
  return errors;
};

export interface ResetPasswordFormData {
  password: string;
  password_check: string;
}

interface ResetPasswordFormProps extends InjectedFormProps<ResetPasswordFormData> {}

const ResetPasswordForm: React.FunctionComponent<ResetPasswordFormProps> = (props) => {
  return (
    <form onSubmit={props.handleSubmit}>
      <div style={{ marginTop: 8 }}>
        <Field
          name="password"
          component={TextField}
          label="비밀번호"
          type="password"
          margin="dense"
          validate={[validator.password]}
          fullWidth
        />
      </div>
      <div>
        <Field
          name="password_check"
          component={TextField}
          label="비밀번호 확인"
          type="password"
          margin="dense"
          fullWidth
        />
      </div>
      <div style={{ marginTop: 8 }}>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          fullWidth
          disabled={props.submitting}
        >
          변경하기
        </Button>
      </div>
    </form>
  );
};

export default reduxForm<ResetPasswordFormData>({
  validate,
  form: 'resetPasswordForm',
})(ResetPasswordForm);
