import React from 'react';
import { Link } from 'react-router-dom';
import Button, { ButtonProps } from '@material-ui/core/Button';

interface LinkButtonProps extends ButtonProps {
  to: string;
  replace?: boolean;
  external?: boolean;
}

const ExternalLink: React.FunctionComponent<LinkButtonProps> = (props) => {
  const { to, external, ...rest } = props;
  return (
    <a
      href={to}
      target="_blank"
      { ...rest }
    />
  );
};

export default (props: LinkButtonProps) => (
  <Button
    { ...props }
    component={
      props.external ? ExternalLink as any : Link as any
    }
  />
);
