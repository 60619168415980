import React, { useState, ChangeEvent } from 'react';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

interface CommentEditorProps {
  onSubmit(body: string): void;
  onCancel?(): void;
  initialValue?: string;
}

const CommentEditor: React.FunctionComponent<CommentEditorProps> = (props) => {

  const [body, setBody] = useState(props.initialValue ? props.initialValue : '');

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setBody(event.target.value);
  };

  const handleSubmit = () => {
    props.onSubmit(body);
    setBody('');
  };

  return (
    <Grid container alignItems="center" spacing={8}>
      <Grid item xs>
        <TextField
          variant="outlined"
          margin="dense"
          onChange={handleChange}
          value={body}
          multiline
          fullWidth
        />
      </Grid>
      <Grid item xs={3} lg={2}>
        <Button
          color="primary"
          size="small"
          onClick={handleSubmit}
          fullWidth
        >
          {props.initialValue ? '댓글 수정' : '댓글 쓰기'}
        </Button>
        {props.onCancel &&
          <Button
            color="primary"
            size="small"
            onClick={handleSubmit}
            fullWidth
          >
            취소
          </Button>
        }
      </Grid>
    </Grid>
  );
};

export default CommentEditor;
