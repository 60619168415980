import api, { ApiPromiseAction, ApiAction } from './../api';
import { AnyAction } from 'redux';
import { fulfilled, pending } from './helper';

const READ_NOTICES = 'notice/readNotices';
const READ_NOTICE = 'notice/readNotice';
const READ_FEATURED_NOTICES = 'notice/readFeaturedNotices';

export function readNotices(page = 1): ApiPromiseAction {
  const request = api.get('/notices/', { page });
  return {
    type: READ_NOTICES,
    payload: request,
    meta: { page },
  };
}

export function readNotice(id: number): ApiPromiseAction {
  const request = api.get(`/notices/${id}`);
  return {
    type: READ_NOTICE,
    payload: request,
  };
}

export function readFeaturedNotices(): ApiPromiseAction {
  const request = api.get('/notices/featured/');
  return {
    type: READ_FEATURED_NOTICES,
    payload: request,
  };
}

export interface Notice {
  id: number;
  title: string;
  body: string;
  is_featured: boolean;
  created_at: string;
  updated_at: string;
}

export interface NoticeState {
  list: Notice[];
  detail: Notice | undefined;
  featuredList: Notice[];
  count: number;
  currentPage: number;
}

const INITIAL_NOTICE_STATE: NoticeState = {
  list: [],
  detail: undefined,
  featuredList: [],
  count: 0,
  currentPage: 1,
};

export default function (
  state: NoticeState = INITIAL_NOTICE_STATE,
  action: ApiAction,
): NoticeState {
  switch (action.type) {
    case fulfilled(READ_FEATURED_NOTICES): {
      return {
        ...state,
        featuredList: action.payload.data,
      };
    }
    case fulfilled(READ_NOTICES): {
      return {
        ...state,
        list: action.payload.data.results,
        count: action.payload.data.count,
        currentPage: action.meta.page,
      };
    }
    case fulfilled(READ_NOTICE): {
      return {
        ...state,
        detail: action.payload.data,
      };
    }
    default: {
      return state;
    }
  }
}
