import api, { setAccessToken, ApiPromiseAction, ApiAction } from '../api';
import { fulfilled, pending } from './helper';
import { Club } from './club';
import { Transaction } from './payment';

const CREATE_MEMBERSHIP = 'club/createMembership';
const READ_MEMBERSHIPS = 'club/readMemberships';

export function createMembership(data: { imp_uid: string }): ApiPromiseAction {
  const request = api.post('/memberships/', data);
  return {
    type: CREATE_MEMBERSHIP,
    payload: request,
  };
}

export function readMemberships(): ApiPromiseAction {
  const request = api.get('/memberships/');
  return {
    type: READ_MEMBERSHIPS,
    payload: request,
  };
}

export interface Membership {
  club: number;
  info: Club;
  transactions: Transaction[];
}

export interface MembershipState {
  list: Membership[];
  detail: Membership | undefined;
}

const INITIAL_MEMBERSHIP_STATE: MembershipState = {
  list: [],
  detail: undefined,
};

export default function (
  state: MembershipState = INITIAL_MEMBERSHIP_STATE,
  action: ApiAction,
): MembershipState {
  switch (action.type) {
    case fulfilled(CREATE_MEMBERSHIP): {
      if (!action.payload.ok) {
        return state;
      }
      return {
        ...state,
        detail: action.payload.data,
      };
    }
    case fulfilled(READ_MEMBERSHIPS): {
      return {
        ...state,
        list: action.payload.data,
      };
    }
    default: {
      return state;
    }
  }
}
