import React, { useEffect, useRef, ChangeEvent } from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import format from 'date-fns/format';

import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ClubListItem from './ClubListItem';
import Progress from '../base/Progress';
import ComingSoon from '../landing/ComingSoon';

import { initializeList, readClubs, setClubFilter, ClubState, ClubFilter } from '../../state/club';
import { AppState } from '../../state/rootReducer';

const Wrapper = styled.div`
  margin: 60px 16px;
`;

interface ClubListProps extends ClubState, RouteComponentProps {
  initializeList: typeof initializeList;
  readClubs: typeof readClubs;
  setClubFilter: typeof setClubFilter;
}

interface ClubFilterTab {
  value: number;
  label: string;
  filterFields: string[];
}

export const clubFilterTabs: ClubFilterTab[] = [
  {
    value: 0,
    label: '모집중인 모임',
    filterFields: ['sale_finish__gt'],
  },
  {
    value: 1,
    label: '진행중인 모임',
    filterFields: ['sale_finish__lte', 'meeting_finish__gt'],
  },
  {
    value: 2,
    label: '지난 모임',
    filterFields: ['meeting_finish__lte'],
  },
];

export const getClubFilter = (value: number) => {
  const filterFields = clubFilterTabs[value].filterFields;
  const clubFilter: ClubFilter = {};
  filterFields.map((field: string) => {
    clubFilter[field] = format(new Date(), 'YYYY-MM-DD');
  });
  return clubFilter;
};

const ClubList: React.FunctionComponent<ClubListProps> = (props) => {

  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current) {
      const clubFilter = getClubFilter(props.filterTab);
      props.setClubFilter(clubFilter, props.filterTab);
      props.readClubs(clubFilter);
      initialized.current = true;
    }
  });

  const handleTabChange = (event: ChangeEvent<{}>, value: number) => {
    const clubFilter = getClubFilter(value);
    props.setClubFilter(clubFilter, value);
    props.readClubs(clubFilter);
  };

  return (
    <Wrapper>
      <Grid container justify="center">
        <Helmet
          title="모임 둘러보기 | 향유고래"
        />
        <Grid item xs={12} md={8}>
        <Tabs
          value={props.filterTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
          style={{ marginBottom: 30 }}
        >
          {clubFilterTabs.map(tab =>
            <Tab key={tab.value} label={tab.label}/>,
          )}
        </Tabs>
          {props.loading && <Progress/>}
          <Grid container spacing={24} justify="center">
          {!props.loading && props.list.length === 0 && <ComingSoon/>}
          {!props.loading && props.list.length > 0 && props.list.map(club => (
            <Grid item xs={12} sm={6} md={4} key={club.id}>
              <ClubListItem
                onClick={() => props.history.push(`/clubs/${club.id}`)}
                club={club}
              />
            </Grid>
          ))}
          </Grid>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

const mapStateToProps = (state: AppState) => ({
  list: state.club.list,
  loading: state.club.loading,
  filter: state.club.filter,
  filterTab: state.club.filterTab,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  { initializeList, readClubs, setClubFilter },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(ClubList);
