import api, { ApiPromiseAction, ApiAction } from './../api';
import { AnyAction } from 'redux';
import { fulfilled, pending } from './helper';

const READ_POSTS = 'board/readPosts';
const READ_POST = 'board/readPost';
const CREATE_POST = 'board/createPost';
const UPDATE_POST = 'board/updatePost';
const DELETE_POST = 'board/deletePost';
const READ_COMMENTS = 'board/readComments';
const CREATE_COMMENT = 'board/createComment';
const UPDATE_COMMENT = 'board/updateComment';
const DELETE_COMMENT = 'board/deleteComment';

export function readPosts(
  club?: number,
  cursor: string | null = null,
  pageSize: number = 5,
): ApiPromiseAction {
  const request = api.get('/posts/', { club, cursor, page_size: pageSize });
  return {
    type: READ_POSTS,
    payload: request,
    meta: { club, cursor },
  };
}

export function readPost(id: number): ApiPromiseAction {
  const request = api.get(`/posts/${id}/`);
  return {
    type: READ_POST,
    payload: request,
  };
}

export function createPost(data: object): ApiPromiseAction {
  const request = api.post('/posts/', data);
  return {
    type: CREATE_POST,
    payload: request,
  };
}

export function updatePost(id: number, data: Partial<PostFormData>): ApiPromiseAction {
  const request = api.patch(`/posts/${id}/`, data);
  return {
    type: UPDATE_POST,
    payload: request,
  };
}

export function deletePost(id: number): ApiPromiseAction {
  const request = api.delete(`/posts/${id}/`);
  return {
    type: DELETE_POST,
    payload: request,
    meta: {
      id,
    },
  };
}

export function readComments(post: number): ApiPromiseAction {
  const request = api.get('/comments/', { post });
  return {
    type: READ_COMMENTS,
    payload: request,
    meta: {
      post,
    },
  };
}

export function createComment(data: CommentFormData): ApiPromiseAction {
  const request = api.post('/comments/', data);
  return {
    type: CREATE_COMMENT,
    payload: request,
  };
}

export function updateComment(id: number, data: Partial<CommentFormData>): ApiPromiseAction {
  const request = api.patch(`/comments/${id}/`, data);
  return {
    type: UPDATE_COMMENT,
    payload: request,
  };
}

export function deleteComment(id: number, post: number): ApiPromiseAction {
  const request = api.delete(`/comments/${id}/`);
  return {
    type: DELETE_COMMENT,
    payload: request,
    meta: {
      id, post,
    },
  };
}

export interface Post {
  id: number;
  user: number;
  user_nickname: string;
  club: number;
  title: string;
  body: string;
  comment_count: string;
  created_at: string;
  updated_at: string;
}

export interface PostFormData {
  user: number;
  club: number;
  title: string;
  body: string;
}

export interface Comment {
  id: number;
  user: number;
  user_nickname: string;
  post: number;
  body: string;
  created_at: string;
  updated_at: string;
}

export interface CommentFormData {
  user: number;
  body: string;
  post: number;
}

export interface BoardState {
  posts: Post[];
  next: string | null;
  currentClub: number | null;
  currentCursor: string | null;
  post: Post | undefined;
  count: number;
  comments: { [postId: number]: Comment[] };
  loadingPosts: boolean;
}

const INITIAL_BOARD_STATE: BoardState = {
  posts: [],
  next: null,
  currentClub: null,
  currentCursor: null,
  post: undefined,
  count: 0,
  comments: {},
  loadingPosts: false,
};

export default function (
  state: BoardState = INITIAL_BOARD_STATE,
  action: ApiAction,
): BoardState {
  switch (action.type) {
    case pending(READ_POSTS): {
      return {
        ...state,
        loadingPosts: true,
      };
    }
    case fulfilled(READ_POSTS): {
      let posts;
      if (action.meta.cursor === null) {
        posts = action.payload.data.results;
      } else {
        posts = state.posts.concat(action.payload.data.results);
      }
      return {
        ...state,
        posts,
        count: action.payload.data.count,
        next: action.payload.data.next,
        currentClub: action.meta.club,
        currentCursor: action.meta.cursor,
        loadingPosts: false,
      };
    }
    case fulfilled(READ_POST): {
      return {
        ...state,
        post: action.payload.data,
      };
    }
    case fulfilled(CREATE_POST): {
      const newPost = action.payload.data as Post;
      const posts = [newPost].concat(state.posts);
      return {
        ...state,
        posts,
      };
    }
    case fulfilled(UPDATE_POST): {
      const posts: Post[] = [];
      state.posts.map((post) => {
        if (post.id !== action.payload.data.id) {
          posts.push(post);
        } else {
          posts.push(action.payload.data);
        }
      });
      return {
        ...state,
        posts,
        post: action.payload.data,
      };
    }
    case fulfilled(DELETE_POST): {
      const posts: Post[] = [];
      state.posts.map((post) => {
        if (post.id !== action.meta.id) {
          posts.push(post);
        }
      });
      return {
        ...state,
        posts,
      };
    }
    case fulfilled(READ_COMMENTS): {
      return {
        ...state,
        comments: {
          ...state.comments,
          [action.meta.post]: action.payload.data,
        },
      };
    }
    case fulfilled(CREATE_COMMENT): {
      const newComment = action.payload.data as Comment;
      const comments = state.comments[action.payload.data.post].concat([newComment]);
      return {
        ...state,
        comments: {
          ...state.comments,
          [action.payload.data.post]: comments,
        },
      };
    }
    case fulfilled(UPDATE_COMMENT): {
      const comments: Comment[] = [];
      state.comments[action.payload.data.post].map((comment) => {
        if (comment.id !== action.payload.data.id) {
          comments.push(comment);
        } else {
          comments.push(action.payload.data);
        }
      });
      return {
        ...state,
        comments: {
          ...state.comments,
          [action.payload.data.post]: comments,
        },
      };
    }
    case fulfilled(DELETE_COMMENT): {
      const comments: Comment[] = [];
      state.comments[action.meta.post].map((comment) => {
        if (comment.id !== action.meta.id) {
          comments.push(comment);
        }
      });
      return {
        ...state,
        comments: {
          ...state.comments,
          [action.meta.post]: comments,
        },
      };
    }
    default: {
      return state;
    }
  }
}
