import React from 'react';
import styled from 'styled-components';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Section from './Section';
import LinkButton from '../base/LinkButton';

import * as colors from '../../assets/values/colors';

const LineBlock = styled.div`
  display: inline-block;
`;

const Description = styled.div`
  font-size: 16px;
  font-weight: 400;
  margin-top: 10vh;
  color: ${colors.textBlue};
  text-align: center;
  line-height: 1.5;
`;

const Phrase = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin-top: 16px;
  color: ${colors.textBlue};
  text-align: center;
  line-height: 1.5;
`;

const Wanted: React.FunctionComponent = () => {
  return (
    <Section color="white" title="모임 만들 사람을 찾습니다!">
      <Description>
        <LineBlock>나만 알던, 그러나 너도 좋아할만한</LineBlock> <LineBlock>문화예술을 함께 공유하고 싶은 분들</LineBlock><br/>
        <LineBlock>더 깊게 파고들 수 있는</LineBlock> <LineBlock>공간을 찾으시는 분들</LineBlock>
      </Description>
      <Phrase>
        7명만 있으면 모임을 열 수 있습니다!
      </Phrase>
      <Grid container justify="center">
        <Grid item xs={12} md={3}>
          <LinkButton
            to="https://goo.gl/forms/l4kkA6zIEtjk15hS2"
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            style={{ margin: '30px 0px', padding: '12px 0px' }}
            target="blank"
            external
          >
            모임 제안하기
          </LinkButton>
        </Grid>
      </Grid>
    </Section>
  );
};

export default Wanted;
