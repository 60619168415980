import React from 'react';
import styled from 'styled-components';

import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';

import { formatKRW } from '../../utils/formatter';

interface PaymentPriceProps {
  discountedPrice: number;
  fullPrice: number;
  maximumCapacity: number;
  minimumCapacity: number;
  memberCount: number;
  totalMonth: number;
}

const OriginalPrice = styled.div`
  text-decoration: line-through;
  line-height: 1;
`;

const CurrentPrice = styled.div`
`;

const PaymentPrice: React.FunctionComponent<PaymentPriceProps> = (props) => {
  if (props.memberCount >= props.maximumCapacity) {
    return (
      <div>
        <CurrentPrice>
          <Typography
            variant="h6"
            color="primary"
            style={{ display: 'inline-block', verticalAlign: 'middle' }}
          >
            {formatKRW(props.fullPrice)}
          </Typography>
          <Chip
            label="모집 종료"
            style={{ marginLeft: 8 }}
          />
        </CurrentPrice>
      </div>
    );
  }
  if (props.memberCount > props.maximumCapacity - 2) {
    return (
      <div>
        <CurrentPrice>
          <Typography
            variant="h6"
            color="primary"
            style={{ display: 'inline-block', verticalAlign: 'middle' }}
          >
            {formatKRW(props.fullPrice)}
          </Typography>
          <Chip
            label="마감 임박!"
            style={{ marginLeft: 8 }}
          />
        </CurrentPrice>
        <div>
          <Typography
            variant="subtitle2"
            color="primary"
            style={{ display: 'inline-block', verticalAlign: 'middle' }}
          >
            {formatKRW(props.fullPrice / props.totalMonth)}/월
          </Typography>
        </div>
      </div>
    );
  }
  if (props.memberCount >= props.minimumCapacity) {
    return (
      <div>
        <CurrentPrice>
          <Typography
            variant="h6"
            color="primary"
            style={{ display: 'inline-block', verticalAlign: 'middle' }}
          >
            {formatKRW(props.fullPrice)}
          </Typography>
          <Chip
            label="모임 확정! 추가모집중"
            style={{ marginLeft: 8 }}
          />
        </CurrentPrice>
        <div>
          <Typography
            variant="subtitle2"
            color="primary"
            style={{ display: 'inline-block', verticalAlign: 'middle' }}
          >
            {formatKRW(props.fullPrice / props.totalMonth)}/월
          </Typography>
        </div>
      </div>
    );
  }
  return (
    <div>
      <OriginalPrice>
        <Typography variant="subtitle2" color="primary">
          {formatKRW(props.fullPrice)}
        </Typography>
      </OriginalPrice>
      <CurrentPrice>
        <Typography
          variant="h6"
          color="primary"
          style={{ display: 'inline-block', verticalAlign: 'middle' }}
        >
          {formatKRW(props.discountedPrice / props.totalMonth)}/월
        </Typography>
        <Chip
          label="얼리버드 할인중!"
          style={{ marginLeft: 8 }}
        />
      </CurrentPrice>
      <div>
        <Typography
          variant="subtitle2"
          color="primary"
          style={{ display: 'inline-block', verticalAlign: 'middle' }}
        >
          총 {formatKRW(props.discountedPrice)}
        </Typography>
      </div>
    </div>
  );
};

export default PaymentPrice;
