import React from 'react';
import styled from 'styled-components';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';

import Button from '@material-ui/core/Button';

import TextField from '../form/TextField';
import * as validator from '../../utils/formValidator';

const ButtonWrapper = styled.div`
  margin-top: 12px;
`;

export interface SignInFormData {
  email: string;
  password: string;
}

const SignInForm: React.FunctionComponent<InjectedFormProps<SignInFormData>> = (props) => {
  return (
    <form onSubmit={props.handleSubmit}>
      <div>
        <Field
          name="email"
          component={TextField}
          label="이메일"
          margin="dense"
          validate={[validator.email]}
          fullWidth
        />
      </div>
      <div>
        <Field
          name="password"
          component={TextField}
          label="비밀번호"
          type="password"
          margin="dense"
          validate={[validator.password]}
          fullWidth
        />
      </div>
      <ButtonWrapper>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          disabled={props.submitting}
          fullWidth
        >
          로그인
        </Button>
      </ButtonWrapper>
    </form>
  );
};

export default reduxForm<SignInFormData>({
  form: 'signInForm',
})(SignInForm);
